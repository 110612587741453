import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import Hero from '../../components/home/Hero';
import What from '../../components/home/What';
import Why from '../../components/home/Why';
import Signup from '../../components/home/Signup';
import How from '../../components/home/How';
import FAQ from '../../components/home/FAQ';
import LoginModal from '../../components/forms/LoginModal';
import { segmentTrack } from '../../includes/analytics';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import BikeSVG from '../../images/bike.inline.svg';

const BikeBanner = styled.div`
  background-color: #F4F6FE;
  border: 1px solid #E6EBFD;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #000;
  line-height: 22px;
  padding: 12px 20px;
  font-size: 16px;
  margin-top: 24px;
  max-width: 560px;
  text-align: left;

  a {
    color: #000;
  }

  @media(max-width: 1023px) {
    position: static;
  }

  @media(max-width:767px) {
    padding: 12px 18px;
    font-size: 14px;
  }

  .bike-icon{
    background-color: #1537C7;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 7px;
    margin-right: 24px;

    @media(max-width:767px) {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
`;

const BikeIconWrapper = styled.div`
  background-color: #1537C7;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 7px;
  box-sizing: border-box;
  margin-right: 24px;

  @media(max-width:767px) {
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

export type IndexProps = {
  data: IndexQueryData
};
export default function Index({ data }:IndexProps) {
  segmentTrack('dasher_application_page');

  return <Layout>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta name="description" content={data.site.siteMetadata.description} />
      <title>{data.site.siteMetadata.name}</title>
    </Helmet>
    <LoginModal />
    <Hero
      headline={<>
        <div className="title-row">Your bike.</div>
        <div className="title-row">Your time.</div>
        <div className="title-row">You're the boss.</div>
      </>}
      subHeadline="Deliver on your bike for faster routes on your schedule."
      img={<StaticImage className="img" src="../../images/hero/a080b4b9f9cb494db62ba7276dc6ac9b.jpg" alt="" layout="fullWidth" placeholder="none" />}
    >
      <BikeBanner>
        <BikeIconWrapper>
          <BikeSVG/>
        </BikeIconWrapper>
        <p>Enjoy two weeks FREE on a <a rel="noopener noreferrer" target="_blank" href="https://www.ridezoomo.com/us/doordash">Zoomo ebike</a> when you deliver on your bike with DoorDash</p>
      </BikeBanner>
    </Hero>
    <What />
    <Why />
    <Signup />
    <How />
    <FAQ />
  </Layout>;
}

type IndexQueryData = {
  site: {
    siteMetadata: {
      name: string,
      description: string,
    }
  }
};
export const query = graphql`
  query {
    site {
      siteMetadata {
        name
        description
      }
    }
  }
`;
